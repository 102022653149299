import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import Layout from '../layout';

const DownloadPage = ({
  pageContext: { data = {}, metaData = {}, menus = [] },
}) => {
  useEffect(() => {
    navigate('/download');
  }, []);

  return (
    <Layout SEOTitle={data.label} metaData={metaData} menus={menus}></Layout>
  );
};

export default DownloadPage;
